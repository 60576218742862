import React from "react"
import PatternImg from "../../svg/blocks.svg"

function BackgroundPattern() {
   return (
      <div
         className={`absolute -z-50 left-1/2 md:w-[100vw]
                     transform -translate-x-1/2 h-[30rem] md:h-[40rem] overflow-y-hidden
                     opacity-[30%]`}
      >
         <span
            className={`absolute bottom-0 h-[100%] inset-x-0
                        bg-gradient-to-t from-background via-background/70 to-transparent0`}
         ></span>
         <PatternImg />
      </div>
   )
}

export default BackgroundPattern
