import React from "react"
import { Link } from "gatsby"

function Button({
   linkTo,
   href,
   label,
   title,
   iconLeft,
   iconRight,
   size,
   isDark,
   customOuterClasses,
   customTextClasses,
   onClick,
   isRounded,
}) {
   const defaultClasses = "py-1 px-3 md_3:pl-3.5 md_3:pr-4 bg-white/20 backdrop-blur-md"

   const iconClasses = `group-hover/button:underlined group-hover/button:text-dark
                        ${size === "small" ? "w-6 md:w-6" : "w-7 md:w-7"}`

   // const sizeClasses = size === "small" ? "text-xs" : size === "regular" ? "" : ""

   const containerClasses = `group/button flex items-center space-x-2 bg-dark inline-block text-center
                             ${
                                isDark
                                   ? "bg-dark border border-offWhite hover:bg-primary"
                                   : "bg-transparent hover:bg-stone-200 border border-dark decoration-wavy"
                             }
                             ${isRounded ? "border-radius-md overflow-hidden" : "border-radius-sm"}
                             ${customOuterClasses ? customOuterClasses : defaultClasses}`

   const textClasses = ` ${size === "small" ? "text-xs font-bodybold" : size === "regular" ? "font-normal" : ""}
      ${
         isDark
            ? "inline-block font-mono uppercase text-white group-hover/button:text-white group-hover/button:underlined"
            : "inline-block font-mono uppercase text-dark group-hover/button:text-dark group-hover/button:underlined group-hover/button:text-dark "
      }`

   const ButtonComponent = (
      <button className={containerClasses} onClick={onClick} title={label} aria-label={title} role="button">
         {iconLeft && <span className={`${textClasses} ${iconClasses}`}>{iconLeft}</span>}
         <p className={`${textClasses} ${customTextClasses}`}>{label}</p>
         {iconRight && <span className={`${textClasses} ${iconClasses} `}>{iconRight}</span>}
      </button>
   )

   if (linkTo) {
      return <Link to={linkTo}>{ButtonComponent}</Link>
   } else if (href) {
      return (
         <a rel="noopener noreferrer" target="_blank" href={href}>
            {ButtonComponent}
         </a>
      )
   } else if (!href || !linkTo === null) {
      return <Link to={"#0"}>{ButtonComponent}</Link>
   }
}

export default Button
