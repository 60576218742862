import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import ContainerSite from "../3_layouts/ContainerSite"
import Navigation from "../2_sections/Navigation"
import Footer from "../2_sections/Footer"
import TransitionPageIn from "../3_layouts/TransitionPageIn"
import BackgroundPattern from "../5_assets/motion/BackgroundPattern.js"
import ScrollToTopButton from "../1_components/ScrollToTopButton"

function Layout({ children, location }) {
   const allowedPathsBackgroundPattern = ["/", "/404/"]
   const showBackgroundPattern = allowedPathsBackgroundPattern.includes(location.pathname)
   // const showScrollToTopButton = location.pathname.split("/")[1]

   return (
      <div className="relative h-full w-full">
         <TransitionPageIn>
            <Navigation />
            {showBackgroundPattern && <BackgroundPattern />}
            <main>
               <ContainerSite>{children}</ContainerSite>
            </main>
         </TransitionPageIn>

         <div className={`sticky w-[76vw] mx-auto text-right bottom-[6vh] z-50`}>
            <ScrollToTopButton />
         </div>

         <Footer />
      </div>
   )
}

Layout.propTypes = {
   children: PropTypes.node.isRequired,
   location: PropTypes.object.isRequired,
}

export default Layout
