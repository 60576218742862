import React, { useState, useEffect, useCallback } from "react"
import IconArrow from "../svg/icons/arrow-curve-up.svg"

const ScrollToTop = ({ showBelow = 600, label = "" }) => {
   const [show, setShow] = useState(false)

   const handleScroll = useCallback(() => {
      setShow(window.scrollY > showBelow)
   }, [showBelow])

   useEffect(() => {
      if (showBelow) {
         window.addEventListener("scroll", handleScroll, { passive: true })
         return () => window.removeEventListener("scroll", handleScroll)
      }
   }, [showBelow, handleScroll])

   const handleClick = () => {
      window.scrollTo({ top: 0, behavior: "smooth" })
   }

   return (
      show && (
         <>
            <button
               aria-label="Scroll back to top of page"
               title="Back to top"
               className={`
                           ${show ? "animate-fadeIn" : "animate-fadeOut"}
                           inline-block focus:outline-none mx-auto
                         bg-white/20 hover:bg-white backdrop-blur-sm p-2.5 rounded-full
                           border-2 border-dark hover:border-primary hover:text-primary
                           shadow-hover
                           transform hover:-translate-y-1 transition ease-in-out duration-500
                         `}
               onClick={handleClick}
            >
               <IconArrow className="w-8 rotate-360" alt="" />
               <span className="sr-only">{label}</span>
            </button>
            {label && <p className="body-sm space-top-xxs">{label}</p>}
         </>
      )
   )
}

export default ScrollToTop
